exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/Services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-stories-jsx": () => import("./../../../src/pages/stories.jsx" /* webpackChunkName: "component---src-pages-stories-jsx" */),
  "component---src-pages-story-jsx": () => import("./../../../src/pages/story.jsx" /* webpackChunkName: "component---src-pages-story-jsx" */)
}

